<template>
  <div id="app">
    <main-header />

    <router-view-wrap/>
    <router-view name="popup"/>
    <main-footer/>
   <editor
     v-if="user && editorToOpen"
     id="main-editor"
     :value="editorToOpen.value"
     :type="editorToOpen.type"
     :on-saved="editorToOpen.onSaved"
     :open="!!editorToOpen"
     @close="hideEditor"/>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import MainHeader from './views/components/MainHeader'
import Editor from './views/editor/Editor'
import RouterViewWrap from './views/components/RouterViewWrap'
import MainFooter from '@/views/components/MainFooter'

export default {
  name: 'App',
  components: { MainFooter, RouterViewWrap, Editor, MainHeader },
  computed: {
    ...mapState(['user', 'editorToOpen']),
    adminOrEditor () {
      return !!this.user && (this.user.role === 'admin' || this.user.role === 'editor')
    },
    showSecondView () {
      return this.$route.name !== 'home'
    }
  },
  created () {
    this.detectTouching()
  },
  mounted () {
    window.addEventListener('resize', this.setVewPortHeightToFixIOSBag)
    this.setVewPortHeightToFixIOSBag()
  },
  methods: {
    ...mapActions(['setUseTouch', 'hideEditor']),

    detectTouching () {
      window.addEventListener('touchstart', function onFirstTouch () {
        this.setUseTouch()
        window.removeEventListener('touchstart', onFirstTouch, false)
      }.bind(this), false)
    },

    setVewPortHeightToFixIOSBag () {
      const doc = document.documentElement
      doc.style.setProperty('--win-height', `${window.innerHeight}px`)
    }
  }
}
</script>
<style lang="scss">
  @import "https://fonts.googleapis.com/icon?family=Material+Icons";
  // @import "https://fonts.googleapis.com/css2?family=Barlow+Condensed&display=swap";
  @import "https://webfonts2.radimpesko.com/RP-W-55487c3b934c2b2932000005.css";
  @import "./styles/main.scss";
  @import "./styles/vars";

  :root {
    // --font-main: 'Barlow Condensed', sans-serif;
    --win-height: 100%;
    --font-main: 'F Grotesk Book', sans-serif;
    --bg-color: #{$color-bg};
    --bgocolor-semi: #{transparentize($color-bg, 0.05)};
  }
  body {
    background: $color-bg;
    font-family: var(--font-main);
    width: 100vw;
    height: 100vh;
    height: var(--win-height);
  }
  html {
  }

  #app {
    width: 100vw;
    height: 100%;
  }

  .post-feed {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: calc(100% - #{$base-size * 2});
  }
</style>
