import vimeo from '@/lib/vimeo'
import mixcloud from '@/lib/mixcloud'
import regexUrl from '@/lib/regex-weburl'
import simpleId from '@/lib/simpleId'

export default async function attachmentFromUrl (url) {
  if (!url) {
    return Promise.reject(new Error('Empty url'))
  }
  if (vimeo.isVimeoVideoUrl(url)) {
    const vimeoEmbed = await vimeo.getVimeoVideoInfo(url)
    return vimeo.castEmbedToAttachment(url, vimeoEmbed)
  }
  if (mixcloud.isMixCloudUrl(url)) {
    const mixcloudEmbed = await mixcloud.getMixCloudUrlInfo(url)
    return mixcloud.castEmbedToAttachment(mixcloudEmbed)
  }
  return castUrlToAttachment(url)
}

function castUrlToAttachment (url) {
  if (!regexUrl.test(url)) {
    return Promise.reject(new Error('Url is not valid'))
  }
  const host = url.split('/')[2]
  const w = 16
  const h = 10 // 16:10
  return {
    id: simpleId(),
    name: host,
    type: 'embed/html',
    srcSet: {
      original: { url, dimensions: { w, h } }
    },
    order: null,
    err: null
  }
}
