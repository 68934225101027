<template>
  <div class="location">
    <search-input :query="queryLocation">

    </search-input>
    <px-input
      v-model.lazy="address"
      label="Location"
      class=""
      :error="error"
      lazy>
    </px-input>
  </div>
</template>

<script>
import PxInput from './PxInput'
import SearchInput from './SearchInput'

export default {
  name: 'Location',
  components: { SearchInput, PxInput },
  props: {
    value: { type: Object, default: () => ({}) }
  },

  data: () => ({
    locationObj: null,
    geocoder: null,
    tmpAddress: '',
    tmpLatLng: null,
    MarkerClass: null,
    markerEl: null,
    markerInstance: null,
    markersStock: {},
    map: null,
    error: null
  }),

  computed: {
    address: {
      get () { return this.tmpAddress || this.value.address },
      async set (newValue) {
        this.tmpAddress = newValue
        this.$emit('input', location)
      }
    }
  }
}
</script>

<!--suppress CssInvalidAtRule -->
<style lang="scss">
  .location {
    .desc {
      padding: 0 !important;
      .map {
        @apply mt-sm;

        .gm-style {
          & > .gmnoprint,
          & > .gmnoscreen,
          & > [draggable='false'],
          & > div > a {
            display: none !important;
          }
        }
      }
    }
  }
</style>
