<template>
  <div ref="post-feed" class="post-feed-grid" :style="feedHeightStyle">
    <canvas ref="canvas"/>
    <template v-if="!posts.length">
      <div class="flex items-center justify-center">
        <div v-if="processing" class="text-aba-blue">Loading...</div>
        <div v-else>Nothing there</div>
      </div>
    </template>
    <template v-else>
      <div
        v-for="(post, i) in posts"
        :key="post.id"
        class="post-cell-wrap"
        :style="{...cellAbsolutePositionStyle(i), height: textSize + 'px'}">
        <slot name="post-cell" :post="post" :cell-offset-style="cellOffsetPositionStyle(i)">
          <router-link
            v-if="post.type === 'back-button'"
            :to="{ name: 'home' }"
            class="back-button relative"
            :style="cellOffsetPositionStyle(i)">
            <span class="material-icons">north_west</span>
          </router-link>
          <post-cell
            v-else
            :post="post"
            :style="cellOffsetPositionStyle(i)">
          </post-cell>
        </slot>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import placeLetters from '../../lib/random-placement'
import { convertRemToPxValue } from '../../lib/convert-css-units'

import PostCell from './PostCell'

export default {
  name: 'PostFeed',
  components: { PostCell },
  props: {
    posts: { type: Array, default: () => [] },
    withoutQuickEdit: { type: Boolean, default: false },
    processing: Boolean
  },

  data: () => ({
    textSize: 80,
    parentEl: null,
    parentW: null,
    parentH: null,
    letterScale: null,
    feedHeightStyle: null,
    titleLetterElement: null
  }),

  computed: {
    ...mapState(['user']),
    adminOrEditor () {
      return !!this.user && (this.user.role === 'admin' || this.user.role === 'editor')
    },

    points () {
      const rawPoints = placeLetters(this.posts.length, this.letterScale)
      if (!rawPoints.length) return []
      const scale = 1 // 1 / rawPoints[rawPoints.length - 1].y
      const allH = rawPoints[rawPoints.length - 1].y - rawPoints[0].y
      const hShift = allH <= 1 ? rawPoints[0].y * -1 : 0
      return rawPoints.map(point => ({
        ...point,
        xW: Math.floor(this.parentW * point.x),
        yH: Math.floor(this.parentH * (point.y + hShift)),
        yScaled: (point.y + hShift) * scale
      }))
    }

  },
  created () {
  },
  async mounted () {
    this.parentEl = this.$refs['post-feed']
    this.setParentSizeVars()
    window.addEventListener('resize', this.setParentSizeVars)
    this.drawLines()
  },

  watch: {
    processing () {
      if (!this.processing) this.setContentLoaded()
    },
    async points () {
      this.setFeedHeightStyle()
      await this.$nextTick()
      // this.setParentSizeVars()
      // await this.$nextTick()
      this.drawLines()
    }
  },

  methods: {
    ...mapActions(['showEditor', 'setContentLoaded']),
    setParentSizeVars () {
      const parent = this.parentEl
      if (!parent) return
      if (!this.parentComputedStyle) {
        this.parentComputedStyle = getComputedStyle(this.parentEl)
      }
      const { height, width } = parent.getBoundingClientRect()
      this.parentW = width || window.innerWidth
      this.parentH = height || window.innerHeight

      const fontSize = convertRemToPxValue(this.parentComputedStyle.getPropertyValue('--title-letter-font-size'))
      this.letterScale = fontSize / this.parentH
    },

    setFeedHeightStyle () {
      const titleEl = document.querySelector('.post-cell-wrap .title-letter')
      const titleH = titleEl ? titleEl.getBoundingClientRect().height : 0
      // const lastP = this.points[this.points.length - 1]
      this.feedHeightStyle = { height: this.points.length * titleH + 'px' }
    },

    openEditor (post) {
      this.showEditor({
        type: post.type,
        value: post
      })
    },

    cellAbsolutePositionStyle (i) {
      if (!this.points[i]) return null
      const { x, yScaled } = { ...this.points[i] }
      return {
        top: yScaled * 100 + '%',
        left: x * 100 + '%'
      }
    },

    cellOffsetPositionStyle (i) {
      if (!this.points[i]) return null
      const { offset } = { ...this.points[i] }
      let top, bottom
      if (offset.y < 0) {
        bottom = '100%'
      } else {
        top = offset.y > 0 ? '0' : '-50%'
      }
      const left = offset.x < 0 ? '-100%' : '0'
      return {
        bottom,
        top,
        left
      }
    },

    closeEditor () {
      this.postToEdit = null
    },

    drawLines () {
      /** @type HTMLElement */
      const parent = this.parentEl
      if (!parent) return
      const { width, height } = parent.getBoundingClientRect()
      /** @type HTMLCanvasElement */
      const canvas = this.$refs.canvas
      if (!canvas) return
      const lastY = this.points.length ? this.points[this.points.length - 1].y : 1
      // const
      canvas.width = width * window.devicePixelRatio
      canvas.height = height * lastY * window.devicePixelRatio
      canvas.style.height = 100 * lastY + '%'

      const ctx = canvas.getContext('2d')
      let preX, preY
      this.points.forEach(point => {
        let { x, yScaled: y } = { ...point }
        x = x * this.parentW * window.devicePixelRatio
        y = y * this.parentH * window.devicePixelRatio
        // const crossSize = 3
        // ctx.moveTo(x - crossSize, y)
        // ctx.lineTo(x + crossSize, y)
        // ctx.moveTo(x, y - crossSize)
        // ctx.lineTo(x, y + crossSize)
        ctx.moveTo(x, y)
        if (typeof preX !== 'undefined' && typeof preY !== 'undefined') {
          ctx.lineTo(preX, preY)
        }
        preX = x
        preY = y
      })
      ctx.stroke()
    }
  }
}
</script>

<!--suppress CssInvalidAtRule -->
<style lang="scss">
  @import "../../styles/vars";
  @import "../../styles/mixins";

  .post-feed-grid {
    --title-letter-font-size: 3.75rem;

    width: calc(100vw - #{$base-size * 2});
    max-width: 800px;
    box-sizing: content-box;
    min-height: calc(100vh - #{$base-size} * 2);
    position: relative;
    margin: $base-size * 2 auto 0;

    .post-cell-wrap {
      position: absolute;
      transition: left 0.2s, top 0.2s;
      .post-cell {
        position: relative;
      }
    }

    .back-button {
      .material-icons{
        font-size: var(--title-letter-font-size);
      }
    }

    canvas {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
  }
</style>
