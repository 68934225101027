<template>
  <div class="post-editor">
    <dropdown-select
      v-model="postStatus"
      label="Publication Status"
      :options="postStatusList"
      class="mr-base"/>
    <px-input
      v-model="postData.title"
      :placeholder="`Work title`"
      class="xl"/>
    <attachments-editor
      ref="attachments-editor"
      v-model="attachments"
      :author-id="authorId"
      :poster="posterId"
      @remove="onRemoveAttachment"
      @set-poster="posterId = $event"/>
    <px-input
      v-model="workYear"
      placeholder="Year"
      type="number"/>
    <px-input
      v-model="postData.technique"
      placeholder="Technique"/>
    <px-input
      v-model="postData.credits"
      placeholder="Credits"/>
    <tags-input v-if="adminOrEditor" v-model="tags" label="Tags"/>
    <text-editor
      ref="text-editor"
      v-model="content"
      label="Work Description"/>
  </div>
</template>

<script>
import PostEditorMixin from '../../../mixins/post-editor'
import PxInput from '../../components/UI/inputs/PxInput'
import TextEditor from '../../components/UI/TextEditor'
import AttachmentsEditor from '../attachments/AttachmentsEditor'
import TagsInput from '../../components/UI/inputs/TagsInput'
import DropdownSelect from '../../components/UI/DropdownSelect'

export default {
  name: 'PostEditor',
  mixins: [PostEditorMixin],
  components: { DropdownSelect, TagsInput, PxInput, TextEditor, AttachmentsEditor },
  props: {
    open: { type: Boolean, required: true },
    value: { type: Object, default: null },
    type: { type: String, default: 'post', validator: v => !v || ['post', 'event'].includes(v) }
  },

  data: () => ({
    postData: {},
    emptyPostData: {
      author: null,
      type: 'post',
      title: '',
      attachments: null,
      thumbnail: '',
      content: '',
      technique: '',
      credits: '',
      year: null,
      tags: null,
      status: 'public'
    },
    posterTempId: '',
    textEditorFocused: false,
    unsubscribe: null,
    institutions: null
  }),

  computed: {
    workYear: {
      get () {
        return this.postData.year || this.yearNow()
      },
      set (newVal) {
        this.postData.year = parseInt(newVal) || this.yearNow()
      }
    }
  },

  watch: {
    value (value) {
      this.setPostData()
    }
  },

  created () {
    this.setPostData()
  },

  methods: {
    preventEnter (e) {
      return false
    },

    yearNow () { return (new Date()).getYear() + 1900 },

    onEsc (e) {
      e.stopImmediatePropagation()
      let escaped = false
      if (this.$refs.date) {
        escaped = this.$refs.date.onEsc(e)
        if (escaped) return
      }
      if (this.$refs['text-editor']) {
        escaped = this.$refs['text-editor'].onEsc(e)
        if (escaped) return
      }
      this.close()
    },

    addAttachment () {
      if (this.$refs['attachments-editor']) {
        this.$refs['attachments-editor'].openFileDialog()
      }
    },

    embedUrl () {
      if (this.$refs['attachments-editor']) {
        this.$refs['attachments-editor'].openEmbedDialog()
      }
    },

    close () {
      const allSave = true
      if (allSave) {
        if (this.unsubscribe) this.unsubscribe()
        this.postData = { ...this.emptyPostData }
        this.$emit('close')
      }
    },

    setPostData () {
      if (this.value) {
        this.postData = { ...this.emptyPostData, ...JSON.parse(JSON.stringify(this.value)) }
      } else {
        this.postData = { ...this.emptyPostData }
      }
    }
  }
}
</script>
