<template>
  <div class="user-things flex items-center">
    <slot />
    <a
      v-if="viewCanToggleDrafts"
      @click.prevent="toggleDraftsInGrid"
      class="nav-item secondary-item ml-auto select-none text-xs leading-none cursor-pointer">
      <span>{{messageToggleDrafts}}</span>
    </a>
    <sliding-panel ref="user-menu" class="user-menu">
      <template v-slot:trigger="{on, open}">
        <button class="user-menu-button" @click="on">
          <i v-if="user" class="material-icons dimmed" :class="{open, user}">{{open ? 'sentiment_very_satisfied' : 'sentiment_satisfied'}}</i>
          <i v-else class="material-icons dimmed" :class="{open}">chevron_right</i>
        </button>
      </template>
      <div class="user-actions-wrapper">
        <login-form v-if="!user" />
        <div v-else class="user-actions">
          <ul class="user-actions">
            <li v-if="contributor">
              <a @click.prevent="openEditor('post')">Add Work</a>
            </li>
            <li>
              <router-link :to="{name: 'trash'}">
                <span>Trash</span>
              </router-link>
            </li>
          </ul>
          <ul class="user-actions">
            <li v-if="admin">
              <a @click.prevent="openEditor('profile')">Add User</a>
            </li>
            <li>
              <router-link v-if="admin" :to="{name: 'users'}">
                Users
              </router-link>
            </li>
            <li>
              <router-link v-if="admin" :to="{name: 'tags'}">
                Tags
              </router-link>
            </li>
            <li>
              <a @click.prevent="openMyProfileEditor">
                <span>Profile</span>
              </a>
            </li>
          </ul>
          <ul class="user-actions">
            <li class="text-right">
              <a href="#" @click.prevent="logOut">
                <span class="whitespace-no-wrap">Log Out</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </sliding-panel>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import SlidingPanel from './UI/SlidingPanel'
import LoginForm from './login/LoginForm'

export default {
  name: 'UserMenu',
  components: { SlidingPanel, LoginForm },

  data: () => ({
  }),

  computed: {
    ...mapState(['user', 'viewCanToggleDrafts', 'showDraftsInGrid']),
    admin () {
      return this.user && this.user.role === 'admin'
    },
    adminOrEditor () {
      return this.user && (this.user.role === 'admin' || this.user.role === 'editor')
    },
    contributor () {
      return this.user && (this.user.role === 'contributor' || this.adminOrEditor)
    },
    messageToggleDrafts () {
      return this.showDraftsInGrid ? 'Hide Drafts' : `Show ${!this.adminOrEditor ? 'Mine ' : ''}Drafts`
    }
  },

  watch: {
    user () {
      this.$nextTick(() => { if (this.$refs.menu) this.$refs.menu.jumpTop() })
      this.$nextTick(() => { if (this.$refs['user-menu']) this.$refs['user-menu'].jumpTop() })
    }
  },

  methods: {
    ...mapActions(['logOut', 'showEditor', 'updateUser', 'toggleDraftsInGrid']),
    openEditor (type, value) {
      this.showEditor({ type, value })
    },
    openMyProfileEditor () {
      this.showEditor({
        type: 'profile',
        value: this.user,
        onSaved: ({ data }) => {
          if (!data.displayName) return
          this.updateUser({ ...this.user, displayName: data.displayName })
        }
      })
    }
  }
}
</script>

<!--suppress CssInvalidAtRule -->
<style lang='scss'>
  @import '../../styles/vars';
  @import '../../styles/mixins';
  $panel-bg-color: mix(rgba(70, 82, 130, 0.95), $color-bg, 30%);
  .user-menu {
    margin-left: auto;
    * {
     box-sizing: border-box;
    }
    .user-menu-button {
      width: $base-size;
      height: $base-size;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right: $base-padding / 2;

      i {
        transition: transform 0.15s;
        &.open {
          color: white;
          &:not(.user) {
            transform: rotate(90deg);
          }
        }
      }
    }
    .sliding-panel {
      width: 100%;

      //border: $border-thick-w solid $color-aba-blue;
      @include wider-then-phone {
        width: auto;
        margin-right: $base-padding / 2;
      }
    }
    .user-actions-wrapper {
      padding-top: calc(#{$base-size} - #{$border-thick-w});
      background: $panel-bg-color;
      font-weight: 300;
      overflow: hidden;

      ul.user-actions {
        @apply px-sm;
        &:not(:last-child) {
          border-bottom: 1px lighten($panel-bg-color, 10%) solid;
        }
        li {
          display: flex;
          align-items: center;
          min-height: $base-size;
          padding: $base-padding / 2 $base-padding;
          color: #fff;
          background: transparent;
          min-width: 175px;
          &:not(:last-child) {
            border-bottom: 1px lighten($panel-bg-color, 5%) solid;
          }
          & a {
            color: #fff;
            cursor: pointer;
            display: block;
            width: 100%;
            height: 100%;
          }
        }
        button {
          width: auto;
        }
        a {
          margin: 0;
        }
      }
    }
  }
</style>
