<template>
  <div
    class="dimensions-editor flex items-center"
    v-click-outside="onEsc"
    :class="{'bg-red-200': error, focused}">
    <div class="flex-grow">
      <input-flex
        ref="input"
        class="input"
        v-model="dimensionsStr"
        min-width="24"
        @enter="finishEditing"
        @esc="onEsc"
        @focus="focused=true" />
    </div>
    <button
      v-show="focused"
      type="button"
      class="compact"
      @click="finishEditing">
      <i class="material-icons text-sm">done</i>
    </button>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import { restrictInput } from '@/lib/text-input-filter'
import InputFlex from '@/views/components/UI/inputs/InputFlex'

export default {
  name: 'DimensionsEditor',
  directives: { ClickOutside },
  components: { InputFlex },
  props: {
    value: Object
  },
  data: () => ({
    dimensionsTempStr: '',
    focused: false,
    error: false,
    lastValidStr: '',
    active: false
  }),
  computed: {
    dimensionsStr: {
      get () {
        if (this.dimensionsTempStr) return this.dimensionsTempStr
        const { w, h } = this.value || {}
        return typeof w !== 'undefined' && typeof h !== 'undefined' ? `${w} : ${h}` : ''
      },
      set (newValue) {
        this.dimensionsTempStr = newValue
      }
    },
    textEl () {
      return this.$refs.input || null
    }
  },

  mounted () {
    if (this.textEl) {
      restrictInput(this.textEl.$el, /^\s*\d*\s*[/:x]?\s*\d*\s*$/)
    }
  },

  watch: {
    dimensionsStr () {
      this.lastValidStr = this.dimensionsStr
    }
  },

  methods: {
    finishEditing (e) {
      const dims = this.parseInput()
      if (dims) {
        this.$emit('input', dims)
        this.error = false
        this.dimensionsTempStr = ''
        this.textEl.blur()
        this.focused = false
      } else {
        this.error = true
        this.textEl.focus()
      }
    },

    releaseError () {
      if (this.error) this.error = false
    },

    onEsc (e) {
      if (this.focused) {
        this.dimensionsTempStr = ''
        this.error = false
        this.textEl.blur()
        this.focused = false
      }
    },

    onFocus () {
      this.focused = true
    },

    onBlur () {
      setTimeout(() => {
        if (!this.error) this.focused = false
      }, 800)
    },

    parseInput () {
      const match = this.dimensionsStr.match(/(\d+)\s*[/:x ]\s*(\d+)/)
      if (!match) return false
      return { w: parseInt(match[1]), h: parseInt(match[2]) }
    }
  }
}
</script>

<style lang="scss">
.dimensions-editor{
  cursor: text;
  transition: width 0.2s, background 0.2s;

  .input {
    padding: 5px;
    background: transparent;
    min-width: 0 !important;
    &:focus {
      color: var(--color-input-foxus, #0000ff);
    }
  }
}
</style>
