let rootComputedStyle = null

export function convertRemToPxValue (remValue) {
  if (!rootComputedStyle) {
    rootComputedStyle = getComputedStyle(document.getElementsByTagName('html')[0])
  }
  const n = parseFloat(remValue)
  const rem = parseFloat(rootComputedStyle.fontSize)
  return n * rem
}
