<template>
  <editor-popover
    ref="popover"
    :type="postTypeToShow"
    :open="open"
    :buttons="editorButtons"
    :processing="processing"
    :valid="isValid"
    @esc="onEsc"
    @close="$emit('close')"
    @save="save"
    @remove="remove">
    <template v-slot:header>
      <h1
        class="capitalize px-sm truncate overflow-y-visible leading-normal"
        :class="readyToDelete ? 'text-gray-500': 'text-aba-blue'">
        <span :class="{'type-with-title': !!header}">{{postTypeToShow}}</span>
        {{header}}
      </h1>
    </template>
    <component
      v-bind:is="component"
      ref="editor"
      :open="open"
      :value="value"
      :collectionName="dbCollectionName"
      @validated="isValid = $event"
      @set-header="header = $event"
      @setProcessing="processing = $event"
      @allowDelete="editorCanDelete = $event"
      @close="$emit('close')"
      @saved="afterSave"/>
    <template v-if="editorCanDelete" v-slot:footer>
      <div class="">
        <button @click.prevent="readyToDelete = !readyToDelete">
          <i class="material-icons text-gray-600">{{readyToDelete ? 'delete' : 'delete_outline'}}</i>
        </button>
      </div>
    </template>
    <div v-if="processing"
         class="progress-overlay absolute w-full h-full inset-0 flex items-center justify-center z-50 bg-milk">
      <div class="text-xl text-aba-blue">Saving...</div>
    </div>
  </editor-popover>
</template>

<script>
import EventEditor from './editor-types/EventEditor'
import PostEditor from './editor-types/PostEditor'
import ProfileEditor from './editor-types/ProfileEditor'
import EditorPopover from './EditorPopover'
import Spinner from '../components/UI/Spinner'
import PageEditor from './editor-types/PageEditor'
import TagEditor from './editor-types/TagEditor'
import CVEditor from '@/views/editor/editor-types/CVEditor'

export default {
  name: 'Editor',
  components: { Spinner, EditorPopover, EventEditor, PostEditor, ProfileEditor, PageEditor, CVEditor, TagEditor },
  props: {
    open: Boolean,
    value: { type: [Object, String], default: null },
    type: String,
    onSaved: { type: Function, default: null }
  },
  data () {
    return {
      header: '',
      processing: false,
      editorCanDelete: false,
      isValid: true,
      readyToDelete: false
    }
  },
  computed: {
    postType () {
      return this.type || (this.value || {}).type || 'post'
    },
    postTypeToShow () {
      return this.postType === 'post' ? 'work' : this.postType
    },
    component () {
      switch (this.postType) {
        case 'event': return 'EventEditor'
        case 'profile': return 'ProfileEditor'
        case 'page': return 'PageEditor'
        case 'cv': return 'CVEditor'
        case 'tag': return 'TagEditor'
        default: return 'PostEditor'
      }
    },
    dbCollectionName () {
      switch (this.component) {
        case 'InstitutionEditor': return 'institutions'
        case 'TagEditor': return 'tags'
        default: return 'posts'
      }
    },
    headerTitle () {
      return this.header || (this.$refs.editor || {}).headerTitle || ''
    },
    editorButtons () {
      const buttons = { close: 'Cancel' }
      if (this.readyToDelete) {
        buttons.delete = 'Trash It'
      } else {
        buttons.submit = 'Save'
      }
      return buttons
    }
  },
  methods: {
    onEsc () {},
    save () {
      if (typeof (this.$refs.editor || {}).save === 'function') {
        this.$refs.editor.save()
      }
    },
    afterSave (data) {
      if (typeof this.onSaved === 'function') {
        this.onSaved(data)
      } else {
        this.$emit('close')
      }
    },
    remove () {
      if (this.editorCanDelete) {
        this.$refs.editor.remove()
      }
    }
  }
}
</script>
<!--suppress CssInvalidAtRule -->
<style lang="scss" scoped>
  @import "../../styles/vars";
  // .editor {
  //   //font-family: $font-family-sans-serif;
  // }
  .type-with-title {
    @apply text-gray-600 font-thin;
    &:after {
      content: ': '
    }
  }
</style>
