<template>
  <div
    class="post-cell cell flex flex-col"
    :class="[cellSize, post.status, post.type]">
    <header v-if="adminOrEditor" class="flex-grow-0 absolute">
      <div class="flex items-center relative">
        <div v-if="post.status !== 'public'" class="status">
          {{post.status}}
        </div>
      </div>
    </header>
    <popper
      trigger="hover"
      placement="top"
      boundaries-selector="body"
      :boundaries-padding="10">
      <template v-slot:reference>
        <router-link :to="routerLink" class="block">
          <h1 class="title-letter">{{shortTitle}}</h1>
        </router-link>
      </template>
      <router-link :to="routerLink" class="popper-content">
          <div>
            <header v-if="adminOrEditor" class="h-3">
              <a
                v-if="adminOrEditor"
                class="button edit-button absolute top-0 right-0 compact w-2/3base h-2/3base text-gray-600 hover:text-white ml-auto"
                @click.prevent="openEditor(post)">
                <i class="material-icons text-base cursor-pointer">edit</i>
              </a>
            </header>
            <h3 class="uppercase">{{ title }}</h3>
            <div>{{technique}}{{this.post.year ? ` | ${this.post.year}` : ''}}</div>
          </div>
      </router-link>
    </popper>
    <slot></slot>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import * as date from '@/lib/date'
import Popper from '@/views/components/UI/Popper.js'

export default {
  name: 'PostCell',
  components: { Popper },
  props: {
    post: { type: Object, required: true }
  },

  data: () => ({
  }),

  computed: {
    ...mapState(['user']),
    adminOrEditor () {
      return !!this.user && (this.user.role === 'admin' || this.user.role === 'editor')
    },
    routerLink () {
      if (this.post.type === 'post') {
        return { name: 'work', params: { id: this.post.id } }
      } else {
        return { name: 'event', params: { id: this.post.id } }
      }
    },
    routerTypeLink () {
      if (!this.post) return null
      if (this.post.partOfProgramme) {
        return { name: 'programme', params: { id: this.post.partOfProgramme.programmeId } }
      }
      return null
    },

    typeLabel () {
      if (!this.post) return null
      if (this.post.partOfProgramme) {
        return this.post.partOfProgramme.title || this.post.partOfProgramme.singlePostLabel
      } else {
        switch (this.post.type) {
          case 'post': return `Blog ${this.post.author.displayName}`
          case 'event': return 'Event'
          default: return this.post.type
        }
      }
    },
    title () {
      if (this.post.partOfProgramme && this.post.countNumber) {
        return `${this.post.partOfProgramme.singlePostLabel} #${this.post.countNumber}`
      }
      return this.post.title || '?'
    },
    shortTitle () {
      return this.post.shortTitle || this.post.title || '?'
    },

    technique () {
      return this.post.technique || ''
    },

    secondTittle () {
      if (this.post.partOfProgramme && this.post.countNumber) {
        return this.post.title
      }
      return ''
    },
    formattedDate () {
      return date.format(
        this.post.date,
        this.post.type === 'event' && this.upcoming ? 'datetime' : '', 'de'
      )
    },
    cellSize () {
      return (this.post || {}).cardSize || ''
    },
    thumbnailUrl () {
      const post = this.post
      let src = null
      if (post.attachments) {
        // eslint-disable-next-line no-prototype-builtins
        if (post.thumbnail && post.attachments.hasOwnProperty(post.thumbnail)) {
          src = post.attachments[post.thumbnail].srcSet
        } else {
          const firstVisualAttachment = Object.values(post.attachments).find(attachment => {
            return (attachment.type || '').startsWith('image/') ||
              // eslint-disable-next-line no-prototype-builtins
              attachment.srcSet.hasOwnProperty('full') ||
              // eslint-disable-next-line no-prototype-builtins
              attachment.srcSet.hasOwnProperty('preview')
          })
          if (firstVisualAttachment) {
            src = firstVisualAttachment.srcSet
          }
        }
      }
      if (!src) return ''
      const { full, preview, original } = src
      return post.cardSize
        ? (full || original || preview || {}).url
        : (preview || full || original || {}).url
    },
    hosts () {
      if (!this.post || !this.post.participants) return []
      return Object.values(this.post.participants || []).filter(p => p.star)
    },

    participants () {
      if (!this.post || !this.post.participants) return []
      return Object.values(this.post.participants || []).filter(p => !p.star)
    },

    eventData () {
      return {
        eventId: this.post.id,
        eventUrl: window.location.origin + this.$router.resolve(this.routerLink).href,
        eventInfo: {
          title: this.title,
          secondTittle: this.secondTittle,
          dateString: this.formattedDate,
          locationString: (this.post.location || {}).address
        }
      }
    }
  },

  methods: {
    ...mapActions(['showEditor']),
    openEditor (post) {
      this.showEditor({
        type: post.type,
        value: post
      })
    }
  }
}
</script>

<!--suppress CssInvalidAtRule -->
<style lang="scss">
  @import "../../styles/vars";
  @import "../../styles/mixins";
  .post-cell {
    position: relative;
    /*border-bottom: 1px solid #000;*/
    hyphens: auto;

    .title-letter {
      font-size: var(--title-letter-font-size);
    }

    &.upcoming {
      border-bottom: none;
      & > * {
        color: $color-aba-blue
      }
      &:after {
        content: none;
      }
      .excerpt {
        margin-bottom: $small-padding !important;
      }
    }

    &.internal {
      // border-bottom: 1px solid $color-aba-blue;
    }
    &.draft {
      &:after {
        border-style: dashed;
        border-color: $color-aba-red;
      }
      & img {opacity: 0.5;}
      & * {
        font-style: italic !important;
      }

      a {
        @apply text-gray-500;
        &:hover {
          @apply text-aba-red no-underline;
        }
      }
      .status {
        background: $color-aba-red;
      }
    }

    a {
      @apply text-black;
      &:hover {
        @apply text-aba-blue no-underline;
      }
    }

    //.popper, .popper > span {
    //  display: block;
    //}

    a.popper-content {
      width: max-content;
    }

    .type-badge {
      text-transform: capitalize;
    }
    .badge {
      @apply px-sm py-xs text-xs capitalize truncate bg-gray-200;
      border-radius: 1px;
      min-width: 0;
    }

    .upcoming-label {
      position: relative;
      z-index: 10;
      padding: 0.1rem 0.4rem;
      background: $color-aba-blue;
      color: #fff;
      font-size: 75%;
      text-transform: uppercase;
      left: -6px;
      top: -4px;
      transform: rotate(3deg);
    }

    .status {
      @apply text-xs;
      position: absolute;
      white-space: nowrap;
      text-transform: capitalize;
      padding: 0.18rem 0.45rem;
      bottom: calc(100% - 7px);
      left: -5px;
      font-size: 87%;
      background: $color-aba-blue;
      color: white;
      font-weight: lighter;
      border-radius: 1rem;
      z-index: 100;
      transform: rotate(-1.5deg);
    }

    a.edit-button {
      text-decoration: none !important;
    }
    //.edit-button:not(.active) {
    //  opacity: 0;
    //  transform: opacity $transition-time;
    //}
    //.edit-button.active {
    //  background: transparentize($color-dimmed, 0.8);
    //  border-radius: 50%;
    //  color: $color-aba-blue;
    //}

    .excerpt {
      @include multi-line-truncate();
      &.short {
        @include multi-line-truncate(2);
      }
    }

    //&:hover {
    //  .edit-button {
    //    opacity: 1;
    //  }
    //}

    &.horizontal {
      @include wider-then(472px) {
        grid-column: auto / span 2;
        h1 {
          font-size: $h2;
        }
      }
    }

    &.vertical {
      grid-row: span 2;
    }

    &.big {
      grid-row: span 2;
      @include wider-then(472px) {
        grid-column: span 2;
        h1 {
          font-size: $h1;
        }
      }
    }

  }

  .thumbnail-box {
    position: relative;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      filter: saturate(0);

      opacity: 0;
      transition: opacity 0.5s;
      &[lazy=loaded] {
        opacity: 1;
      }
    }
  }
</style>
