<template>
  <div class="post-editor">
    <!-- <dropdown-select
      v-model="postStatus"
      label="Publication Status"
      :options="postStatusList"
      class="mr-base"/> -->
    <px-input
      v-model="postData.title"
      :placeholder="`Tag title`"
      class="xl"/>
  </div>
</template>

<script>
import PostEditorMixin from '../../../mixins/post-editor'
import PxInput from '../../components/UI/inputs/PxInput'

export default {
  name: 'TagEditor',
  mixins: [PostEditorMixin],
  components: { PxInput },
  props: {
    open: { type: Boolean, required: true },
    value: { type: Object, default: null }
  },

  data: () => ({
    postData: {},
    emptyPostData: {
      title: ''
    }
  }),

  computed: {
  },

  watch: {
    value (value) {
      this.setPostData()
    }
  },

  created () {
    this.setPostData()
  },

  methods: {
    onEsc (e) {
      e.stopImmediatePropagation()
      let escaped = false
      if (this.$refs.date) {
        escaped = this.$refs.date.onEsc(e)
        if (escaped) return
      }
      if (this.$refs['text-editor']) {
        escaped = this.$refs['text-editor'].onEsc(e)
        if (escaped) return
      }
      this.close()
    },

    close () {
      const allSave = true
      if (allSave) {
        if (this.unsubscribe) this.unsubscribe()
        this.postData = { ...this.emptyPostData }
        this.$emit('close')
      }
    },

    setPostData () {
      if (this.value) {
        this.postData = { ...this.emptyPostData, ...JSON.parse(JSON.stringify(this.value)) }
      } else {
        this.postData = { ...this.emptyPostData }
      }
    },

    async save () {
      try {
        this.$emit('setProcessing', true)
        if ((this.value || {}).id) {
          await this.collectionRef.doc(this.value.id).update(this.postData)
        } else {
          await this.collectionRef.add(this.postData)
        }
        this.$emit('setProcessing', false)
        this.$emit('saved')
      } catch (e) {
        this.$emit('setProcessing', false)
        console.error('%c savePost() %c e: ', 'background:#ff00AA;color:#000', 'color:#00aaff', e)
      }
    },

    async remove () {
      if (!this.allowDelete) {
        console.error('%c Remove Post %c e: ', 'background:#ff00AA;color:#000', 'color:#00aaff', 'Deleting is not allowed.')
        return
      }
      try {
        this.$emit('setProcessing', true)
        await this.collectionRef.doc(this.value.id).delete()
        // if (typeof this.afterDeleteFunc === 'function') this.afterDeleteFunc()
        this.$emit('setProcessing', false)
        this.$emit('deleted')
        this.$emit('close')
      } catch (e) {
        this.$emit('setProcessing', false)
        console.error('%c Remove Post %c e: ', 'background:#ff00AA;color:#000', 'color:#00aaff', e)
      }
    }

  }
}
</script>
