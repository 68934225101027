export default function placeLetters (numberElements, letterH) {
  const h = 1 - letterH

  if (numberElements < 1) {
    return []
  }

  const maxLineH = Math.max(h / numberElements, letterH * 1.7)
  const points = []
  const angles = []
  const yOffsets = []

  let y = random(0, maxLineH)
  let x = 0
  let lastX = 0
  let lastY = 0

  for (let i = 0; i < numberElements; i++) {
    const offset = { x: 0, y: 0 }
    if (i === 0) {
      x = Math.random()
    } else {
      for (let cc = 0; cc < 20; cc++) {
        x = Math.random()
        if (Math.abs(x - lastX) > 0.1) {
          break
        }
      }
      const incomingAngle = getAngle(lastX, lastY, x, y)
      angles.push(incomingAngle)

      if (i === 1 && x > points[0].x) {
        points[0].offset.x = -1
      }
      offset.x = incomingAngle < 90 ? 1 : -1
    }

    points.push({ x, y, offset })
    lastX = x
    lastY = y
    yOffsets.push(0)
    y += random(letterH, maxLineH)
  }

  const lastAngleIndex = angles.length - 1
  if (lastAngleIndex > -1 && lastAngleIndex < points.length) {
    if (angles[lastAngleIndex] > 90) {
      points[lastAngleIndex].offset.x = 1
    } else {
      points[lastAngleIndex].offset.x = -1
    }
  }

  // check for middle letters if offsets are necessary!
  if (numberElements > 2) {
    for (let i = 1; i < (numberElements - 1); i++) {
      const prePoint = points[i - 1]
      const curPoint = points[i]
      const nextPoint = points[i + 1]
      const anglePre = getAngle(prePoint.x, prePoint.y, curPoint.x, curPoint.y)
      const angleNext = getAngle(curPoint.x, curPoint.y, nextPoint.x, nextPoint.y)
      const tempDiff = angleNext - anglePre
      if (Math.abs(tempDiff) < 60) {
        if ((anglePre > angleNext && curPoint.x > nextPoint.x) || (anglePre < angleNext && curPoint.x < nextPoint.x)) {
          points[i].offset.y = -1 // move up
        } else {
          points[i].offset.y = 1 // move down
        }
      }
    }
  }
  return points
}

function getAngle (x1, y1, x2, y2) {
  return Math.atan2(y2 - y1, x2 - x1) * 180 / Math.PI
}

function random (min, max) {
  return min + Math.random() * (max - min)
}
