<template>
  <footer class="main-footer px-sm">
    <h3 class="uppercase">
      <router-link :to="{name: 'cv'}">Aleksander Komarov</router-link>
      <button
        v-if="allowEdit"
        class="compact top-right"
        @click="openEditor">
        <i class="material-icons text-base">edit</i>
      </button>
    </h3>
  </footer>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'MainFooter',
  components: { },
  props: {},
  data: () => ({}),
  computed: {
    ...mapState(['user']),
    allowEdit () {
      return !!this.user && (this.user.role === 'admin' || this.user.role === 'editor')
    },
    title () {
      const routName = this.$route.name
      switch (routName) {
        case 'home':
        case 'drafts':
        case 'trash':
          return 'Aleksander Komarov'
        case 'about':
          return 'Contact / CV'
      }
      return ''
    }
  },
  methods: {
    ...mapActions(['showEditor']),
    openEditor () {
      if (!this.allowEdit) return
      this.showEditor({ type: 'cv', value: 'cv' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../styles/vars";
.main-footer {
  position: fixed;
  bottom: 2vh;
  left: 2vh;
  height: $base-size;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparentize($color-bg, 0.2);
  h3 {
    position: relative;
    .top-right {
      opacity: 0;
      position: absolute;
      bottom: calc(100% - #{$small-padding});
      left: calc(100% - #{$small-padding});
      transition: opacity 0.2s;
    }
    &:hover .top-right {
      opacity: 1;
    }
  }
}
</style>
