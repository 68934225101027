<template>
  <div class="post-feed flex-col">
    <transition>
      Loading...
    </transition>
    <post-feed
      :posts="posts"
      :processing="processing"
      class="flex-grow">
      <template v-slot:post-cell="{ post, cellOffsetStyle }">
        <div
          class="post-cell cell flex flex-col"
          :style="cellOffsetStyle">
          <popper
            trigger="hover"
            placement="top"
            boundaries-selector="body"
            :boundaries-padding="10">
            <template v-slot:reference>
              <router-link :to="titleLink(post)">
                <h1 class="relative text-6xl capitalize">
                  {{post.shortTitle}}
                </h1>
              </router-link>
            </template>
            <router-link :to="titleLink(post)" class="popper-content">
              <h3 class="uppercase">{{ post.title }}</h3>
            </router-link>
          </popper>
        </div>
      </template>
    </post-feed>
  </div>
</template>

<script>
import { db } from '@/lib/firebase'
import { mapActions, mapState } from 'vuex'
import Popper from '@/views/components/UI/Popper.js'
import PostFeed from './components/PostFeed'

export default {
  name: 'Home',
  components: { PostFeed, Popper },

  data: () => ({
    feed: {},
    includeDrafts: false,
    orderBy: { field: 'year', direction: 'desc' },
    unsubscribe: null,
    processing: false,
    usedTitles: []
  }),
  computed: {
    ...mapState(['user']),
    posts () {
      const uniqueTitles = []
      const tags = Object.values(this.feed).sort((a, b) => b.year - a.year)
      if (this.user) tags.push({ shortTitle: '?', title: 'Without Tags', id: ' ' })
      return tags.map(item => {
        if (item.shortTitle) return item
        const firstL = item.title[0]
        let shortTitle = item.title.substr(0, 1)
        if (uniqueTitles.indexOf(firstL) < 0) {
          uniqueTitles.push(firstL)
        } else {
          shortTitle = item.title.substr(0, 2)
        }
        return { ...item, shortTitle }
      })
    }
  },
  created () {
    this.subscribeTags()
  },
  watch: {
    posts () { this.usedTitles = [] }
    // processing (val) {
    //   if (val) return
    //   setTimeout(() => {
    //     if (!this.$refs['logo-box']) return
    //     const top = this.$refs['logo-box'].getBoundingClientRect().bottom
    //     window.scrollTo({ top, behavior: 'smooth' })
    //   }, 500)
    // }
  },

  methods: {
    ...mapActions(['showEditor']),
    openEditor (value) {
      this.showEditor({ type: 'tag', value })
    },
    titleLink (item) {
      return item.id === 'cv' ? { name: 'cv' } : { name: 'tag', params: { id: item.id } }
    },
    /** @param {String} string */
    postTitle (string) {
      /* DEBUG */
      console.log('%c %c this.usedTitles: ', 'background:#ffbb00;color:#000', 'color:#00aaff', this.usedTitles)

      const uniqueTitle = i => {
        const tempTitle = string.substr(0, i)
        if (this.usedTitles.indexOf(tempTitle) > -1) {
          return uniqueTitle(i + 1)
        } else {
          this.usedTitles.push(tempTitle)
          return tempTitle
        }
      }
      const title = uniqueTitle(1)
      /* DEBUG */
      console.log('%c title %c: ', 'background:#ffbb00;color:#000', 'color:#00aaff', title)
      return title
    },
    subscribeTags () {
      if (typeof this.unsubscribe === 'function') return
      this.unsubscribe = db.collection('tags').onSnapshot({
        next: querySnapshot => {
          querySnapshot.docChanges().forEach(docChange => {
            const doc = docChange.doc
            switch (docChange.type) {
              case 'added':
              case 'modified':
                this.$set(this.feed, doc.id, { ...doc.data(), id: doc.id })
                break
              case 'removed':
                this.$delete(this.feed, doc.id)
            }
          })
          this.processing = false
        },
        error: err => {
          console.error('Subscribe To Tags:', err)
          this.processing = false
        }
      })
    }
  }
}
</script>

<style lang="scss">
  // @import "../styles/vars";
  // @import "../styles/mixins";
  span.popper-trigger {
      display: block;
      height: 100%;
  }
</style>
